.gallery {
    overflow: hidden;
    margin-bottom: 1em;
    padding: 0 6px;
    img { margin-bottom: 15px; }
}

    .gallery_sidebar {
        img { margin-bottom: 15px; }
    }

    .gallery_lightbox {
        > a {
            @include transition(all ease-in-out 300ms);
            overflow: hidden;
            margin-bottom: 1%;
            border: 4px solid transparent;
            img {
                width: 100%;
                @include transition(transform ease-in-out 200ms);
            }
            &:hover {
                @include transition(all ease-in-out 300ms);
                border-color: $white;
                img {
                    @include transition(transform ease-in-out 200ms);
                    @include transform(scale(1.2));
                }
            }

        }
    }

    // G2

    .g2-xs {
        @include media-breakpoint-down(xs) {
            > img, > a, > .img-captioned {
                display: block;
                float: left;
                width: 49.5%;
                &:nth-child(odd) { clear: both; }
                &:nth-child(even) { float: right; }
            }
        }
    }

    .g2-sm {
        @include media-breakpoint-only(sm) {
            > img, > a, > .img-captioned {
                display: block;
                float: left;
                width: 49.5%;
                &:nth-child(odd) { clear: both; }
                &:nth-child(even) { float: right; }
            }
        }
    }

    .g2-md {
        @include media-breakpoint-only(md) {
            > img, > a, > .img-captioned {
                display: block;
                float: left;
                width: 49.5%;
                &:nth-child(odd) { clear: both; }
                &:nth-child(even) { float: right; }
            }
        }
    }

    .g2-lg {
        @include media-breakpoint-only(lg) {
            > img, > a, > .img-captioned {
                display: block;
                float: left;
                width: 49.5%;
                &:nth-child(odd) { clear: both; }
                &:nth-child(even) { float: right; }
            }
        }
    }

    .g2-xl {
        @include media-breakpoint-up(xl) {
            > img, > a, > .img-captioned {
                display: block;
                float: left;
                width: 49.5%;
                &:nth-child(odd) { clear: both; }
                &:nth-child(even) { float: right; }
            }
        }
    }


    // G3

    .g3-sm {
        @include media-breakpoint-only(sm) {
            > img, > a, > .img-captioned {
                display: block;
                float: left;
                width: ((100 - (3 - 1)) / 3) * 1%;
                margin-right: 1%;
                &:nth-child(3n-2) { clear: both; }
                &:nth-child(3n) { float: right; margin-right: 0; }
            }
        }
    }

    .g3-md {
        @include media-breakpoint-only(md) {
            > img, > a, > .img-captioned {
                display: block;
                float: left;
                width: ((100 - (3 - 1)) / 3) * 1%;
                margin-right: 1%;
                &:nth-child(3n-2) { clear: both; }
                &:nth-child(3n) { float: right; margin-right: 0; }
            }
        }
    }

    .g3-lg {
        @include media-breakpoint-only(lg) {
            > img, > a, > .img-captioned {
                display: block;
                float: left;
                width: ((100 - (3 - 1)) / 3) * 1%;
                margin-right: 1%;
                &:nth-child(3n-2) { clear: both; }
                &:nth-child(3n) { float: right; margin-right: 0; }
            }
        }
    }

    .g3-xl {
        @include media-breakpoint-up(xl) {
            > img, > a, > .img-captioned {
                display: block;
                float: left;
                width: ((100 - (3 - 1)) / 3) * 1%;
                margin-right: 1%;
                &:nth-child(3n-2) { clear: both; }
                &:nth-child(3n) { float: right; margin-right: 0; }
            }
        }
    }


    // G4

    .g4-sm {
        @include media-breakpoint-only(sm) {
            > img, > a, > .img-captioned {
                display: block;
                float: left;
                width: ((100 - (4 - 1)) / 4) * 1%;
                margin-right: 1%;
                &:nth-child(4n-3) { clear: both; }
                &:nth-child(4n) { float: right; margin-right: 0; }
            }
        }
    }

    .g4-md {
        @include media-breakpoint-only(md) {
            > img, > a, > .img-captioned {
                display: block;
                float: left;
                width: ((100 - (4 - 1)) / 4) * 1%;
                margin-right: 1%;
                &:nth-child(4n-3) { clear: both; }
                &:nth-child(4n) { float: right; margin-right: 0; }
            }
        }
    }

    .g4-lg {
        @include media-breakpoint-only(lg) {
            > img, > a, > .img-captioned {
                display: block;
                float: left;
                width: ((100 - (4 - 1)) / 4) * 1%;
                margin-right: 1%;
                &:nth-child(4n-3) { clear: both; }
                &:nth-child(4n) { float: right; margin-right: 0; }
            }
        }
    }

    .g4-xl {
        @include media-breakpoint-up(xl) {
            > img, > a, > .img-captioned {
                display: block;
                float: left;
                width: ((100 - (4 - 1)) / 4) * 1%;
                margin-right: 1%;
                &:nth-child(4n-3) { clear: both; }
                &:nth-child(4n) { float: right; margin-right: 0; }
            }
        }
    } 

    .g5-xl {
        @include media-breakpoint-up(xl) {
            > img, > a, > .img-captioned {
                display: block;
                float: left;
                width: ((100 - (5 - 1)) / 5) * 1%;
                margin-right: 1%;
                &:nth-child(5n-4) { clear: both; }
                &:nth-child(5n) { float: right; margin-right: 0; }
            }
        }
    } 
















  