// ===========================================
// BOOTSTRAP'S GRID
// ===========================================

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);
@include _assert-ascending($container-max-widths, "$container-max-widths");

.container {
	@include make-container();
	@include make-container-max-widths();
}

.container-fluid {
	width: 100%;
	@include make-container();
}

.row {
	@include make-row();
}

.no-gutters {
	margin-right: 0;
	margin-left: 0;
	> .wrap {
		padding-right: 0;
		padding-left: 0;
	}
}


// ===========================================
// BACE GRID
// ===========================================

html {
	@include prefix('box-sizing', 'border-box', webkit moz ms o);
    -ms-overflow-style: scrollbar;
}

	*,
	*:before,
	*:after {
		@include prefix('box-sizing', 'inherit', webkit moz ms o)	
	}

	.wrap { @include make-col-ready(); }

	// ==================================================
	// BLOCKS
	// ==================================================

	body.page_front .content {
		.welcome { order: 1; }
		.main { order: 3; }
		.sidebar { order: 2; }

		@include media-breakpoint-up(lg) {
			.row {
				position: relative;
			}
			.welcome { @include make-col(8); padding-right: 30px; }
			.main { @include make-col(8); padding-right: 30px; }
			.sidebar {
				@include make-col(4);
				position: absolute;
				top: 0; right: 0;
			}
		}
		@include media-breakpoint-up(xl) {
			.welcome,
			.main { padding-right: 60px; }
		}
	}

	body.page .content {
		@include media-breakpoint-down(md) {
			.sidebar { padding-top: 15px; }
		}
		@include media-breakpoint-up(lg) {
			.main { @include make-col(8); }
			.sidebar { @include make-col(4); }
		}
/* 		@include media-breakpoint-up(xl) {
	.main { @include make-col(7); }
	.sidebar { @include make-col(5); }
} */
	}
