body {

	min-width: 360px;
	background-color: $body-bg;
	
	//@include media-breakpoint-up(md) {
		background: url("/_/images/layout/white_leather.webp");
	//}

	&:not(.page_front) {
			
		@include media-breakpoint-up(md) {

			height: 100%;
			padding-bottom: 48px;

			.site_info {
				position: absolute;
				bottom: 0;
				width: 100%;
			}

		}
		
	}


}

#directions,
#hours {
	outline: none !important;
	box-shadow: none !important;
}