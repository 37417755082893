@mixin widget-color($color: #333) {
	border-color: $color;
	.widget-title { color: $color; }
	a:not(.btn) { color: $color; }
}

.widget {
	margin-bottom: 15px;
	padding: 20px 20px 25px;
	@include font-size(16);
	line-height: 1.275em;
	border-radius: 8px;
	border: 2px solid #ccc;
	background-color: $white;

	&.widget-primary { @include widget-color($color-primary); }
	&.widget-secondary { @include widget-color($color-secondary); }
	&.widget-highlight { @include widget-color($color-highlight); }
	&.widget-accent { @include widget-color($color-accent); }

	> *:first-child { margin-top: 0; }

	> *:last-child { margin-bottom: 0; }

}

	.widget-title {
		margin: 10px 0 5px;
		letter-spacing: -0.0375em;
	}
