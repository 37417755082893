.front_jumbo {
	padding: 0 15px;
	.container {
		padding: 100px 10px;
		border: 5px solid $white;
		background: url("/_/images/layout/jumbo-bg-1.jpg") center center no-repeat;
		background-size: cover;
	}
	@include media-breakpoint-up(md) {
		.container { padding: 150px 10px; }
	}
	@include media-breakpoint-up(lg) {
		.container { padding: 180px 10px; }
	}
	@include media-breakpoint-up(xl) {
		.container { padding: 210px 10px; }
	}
}

	.jumbo_directory {
		width: auto;
		padding: 0;
		background-color: rgba($white,0.9);

		@include media-breakpoint-up(md) {
			.nav-item { min-width: 220px; }
		}

		@include media-breakpoint-up(lg) {
			.nav-item { min-width: 250px; }
		}

	}

	.jumbo_marketing {
		padding: 10px;
		@include font-size(18);
		font-weight: $body-weight-bolder;
		text-align: center;
		letter-spacing: -0.0325em;
		background: rgba($white, 0.85);
	}