.block_main.content {}

.main {
	>*:last-child { margin-bottom: 0; }

	counter-reset: top-ten;

	h2 {
		counter-increment: top-ten;
		&::before {
			display: inline-block;
			min-width: 40px;
			content: counter(top-ten) ".";
		}
	}

	@include media-breakpoint-up(md) {
		h2::before { min-width: 45px; }
	}

}

